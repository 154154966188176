import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ArrowActionAreaGalleryCard from './galleryCardsWithArrows';  
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import rightImage from '../assets/Search Icon.svg';  
import ImagesOnlyModal from './imagesOnlyModal';  
import CloseIcon from '@mui/icons-material/Close'; 
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '46.5%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80vh',
  bgcolor: 'rgba(191, 184, 155)',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 3,
  flexDirection: 'column',
};

const closeIconContainerStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: '#555',
  borderRadius: '50%',
  padding: '2px', 
  cursor: 'pointer',
  color: 'white',
  width: '25px', 
  height: '25px', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const closeIconStyle = {
  color: 'white',
  fontSize: '24px',
};

export default function GalleryModal({ open, onClose }) {
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImagesOnly, setGalleryImagesOnly] = useState([]);  
  const [openImageModal, setOpenImageModal] = React.useState(false);  

  const [modalTitle, setModalTitle] = React.useState(''); 
  
  useEffect(() => {
    getData();
  }, []);


  const getDataImagesOnly = async (albumId) => {
    try {
      const api_url_base = process.env.REACT_APP_API_URL || '';
        const response = await axios.get(`${api_url_base}/media/albums/${albumId}/images/`);
        console.log("Album Images getDataImagesOnly:", response.data);

        // Construct full image URLs
        const albumImages = response.data.images.map(imagePath => `${api_url_base}${imagePath}`);

        console.log("Processed Album Images:", albumImages); 
        setGalleryImagesOnly(albumImages); // Assuming this state expects an array of URLs
    } catch (error) {
        console.error("Error fetching album images:", error);
    }
};

  const getData = async () => {
    try {
      const api_url_base = process.env.REACT_APP_API_URL || '';
      const response = await axios.get(`${api_url_base}/media/new-albums/`); // fetch albums
      const updatedImages = response.data.map(item => {
        return {
          ...item,
          id: item.album_id, 
          first_image:  `${api_url_base}${item.first_image}`,
        };
      });
      console.log("Processed Albums:", updatedImages); 
      setGalleryImages(updatedImages);
    } catch (error) {
      console.error("Error fetching albums:", error);
    }
  };
  

  const handleOpenImageModal = (albumId, title) => {
    console.log("Album ID:", albumId, "Title:", title);
    setModalTitle(title);
    setOpenImageModal(true);
    getDataImagesOnly(albumId); 
  };
  

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');

  const modalStyle = {
    ...style,
    width: isMobile ? '65%' : '83%',
    maxHeight: isMobile ? '90vh' : '80vh',
    overflow: 'hidden',
  };

  const cardContainerStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : isTablet ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',  
    gap: '16px',
    width: {xs: '100%', sm: '100%', md: '100%'},
    marginTop: {xs: '164px', sm: '100px', md: '80px'},
    marginLeft: {xs: '-5px', sm: '0', md: '0'},
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '8px',
  };

  const cardStyle = {
    height: '210px',
    width: {xs: '200px', sm: '50%', md: '100px'},
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          sx={{
            position: 'absolute',
            top: 36,
            color: 'black',
            fontSize: {xs: '1.3rem', sm: '1.1rem', md: '1.4rem'},
            fontWeight: isMobile ? 500 : 'bold',
            textAlign: {xs: 'center', sm: 'left', md: 'center'},
            marginLeft: {xs: '0', sm: '70px', md: '0'},
            width: '100%',
          }}
        >
          30 Years Of Freedom And Democracy Gallery
        </Typography>

        <Box sx={closeIconContainerStyle} onClick={onClose}>
          <CloseIcon sx={closeIconStyle} />
        </Box>

        <Box sx={{ position: 'absolute', top: {xs: 120, sm: 10 }, right: {xs: 0,  sm: 16, md: 26}, display: 'none', alignItems: 'center', zIndex: 10 }}>
          <TextField
            sx={{ marginRight: '40px', backgroundColor: 'white', width: 250, borderRadius: 7, '& .MuiInputBase-root': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { border: 'none' }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}
            placeholder="Search Event / Date"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={rightImage} alt="search icon" width={25} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        
        <Box sx={cardContainerStyle}>
          {galleryImages.map(galleryImage => (
            <Box sx={cardStyle} key={galleryImage.id}>
              <ArrowActionAreaGalleryCard
                image={galleryImage.first_image}
                title={galleryImage.name}
                description={galleryImage.description}
                onCardClick={() => handleOpenImageModal(galleryImage.id, galleryImage.name)}  
              />
            </Box>
          ))}
        </Box>

        <ImagesOnlyModal
          open={openImageModal}
          onClose={handleCloseImageModal}
          imageSet={galleryImagesOnly} 
          modalTitle={modalTitle} 
        />
      </Box>
    </Modal>
  );
}
