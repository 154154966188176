import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomButton from './customButton';
import ActionAreaCard from './galleryCards';
import GalleryModal from './galleryModal';
import {useTheme, useMediaQuery} from '@mui/material';
import axios from 'axios';
import {useEffect} from 'react';


export default function BannerWithCards({titleText}) {
    const api_url_base = process.env.REACT_APP_API_URL || '';
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        console.log("Fetching album data...");
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(`${api_url_base}/media/new-albums/`);
            const updatedImages = response.data.map(item => {
                item.first_image = `${api_url_base}${item.first_image}`;
                return item;
            });
            setGalleryImages(updatedImages);
        } catch (error) {
            console.error("Error fetching albums:", error);
        }
    };


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    return (
        <Box sx={{py: 4}} id="gallery">
            <Container maxWidth="lg">
                {/* Banner section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'}, //stack elements vertically (column) in mobile mode
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    {/* Left text */}
                    <Typography
                        variant={isMobile ? 'h6' : 'h4'}
                        sx={{
                            fontWeight: 'bold',
                            order: {xs: 1, sm: 0}, // move text up in mobile mode
                            textAlign: {xs: 'center', sm: 'left'}, // and then center text in mobile mode
                        }}
                    >
                        {titleText}
                    </Typography>

                    {/* Right side button (desktop mode) */}
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <CustomButton text="View Full Gallery" onClick={handleOpenModal}/>
                    </Box>
                </Box>

                {/* Cards section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'}, // stack in mobile, row in desktop
                        justifyContent: 'center', //this center will work in mobile mode
                        gap: 2,
                        alignItems: 'center',
                    }}
                >
                    {galleryImages.slice(0, 4).map((galleryImage, index) => (
                        <ActionAreaCard
                            key={index}
                            image={galleryImage.first_image}
                            title={galleryImage.name}
                            description={galleryImage.description}
                        />
                    ))}

                </Box>

                {/* so the button below cards only for mobile responsiveness */}
                <Box
                    sx={{
                        display: {xs: 'flex', sm: 'none'}, // only display in mobile mode
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    <CustomButton text="View Full Gallery" onClick={handleOpenModal}/>
                </Box>
            </Container>

            {/* Modal */}
            <GalleryModal open={open} onClose={handleCloseModal}/>
        </Box>
    );
}
